export default {
  translation: {
    albanian: "Shqip",
    english: "Anglisht",
    "select language": "Zgjedh gjuhën",
    dashboard: "Ballina",
    "find it": "Qysh e lyp, e gjen!",
    "Prona": "Prona",
    "all types":
      "Të gjitha llojet e PATUNDSHMËRIS me qera apo shitje, RESTORANTET më të mirat si dhe MAKINAT me qera dhe shitje në kulmi-ks.",
    "about us": "Rreth nesh",
    "real estate": "Patundshmëri",
    gastronomi: "Gastronomi",
    cars: "Makina",
    rent: "Qera",
    buy: "Blej",
    contactus: "Na kontakto",
    "room mate": "Cimer/e",
    "find city": "Kerko qytetin/lagjen",
    "post type": "Lloji postimit",
    price: "Çmimi",
    min: "min",
    max: "max",
    search: "Kërko",
    "quick solutions": "Ofrojmë zgjedhje të shpejtë për ju!",
    "real estate as you like": "Shtëpi apo banesë ashtu siç e kërkoni",
    "your preferences": "Preferencat tua i gjeni tek ne",
    "best gastronomy": "Restorantet më të mirat në Kosovë",
    "gastronomy types": "Kategori të ndryshme të gastronomisë",
    "fast find": "Kërkim i shpejtë për vozitje të rehatshme",
    "rent buy cars": "Makina me qera apo shitje",
    "find quick": "E gjeni shumë shpejtë",
    "find within 5 minutes": "Brenda 5 minuta do e gjeni at që ju e kërkoni",
    "best apartments": "Apartamentet më të mirat",
    "best offers": "Ju sjellim ofertat më të mirat.",
    "visit all": "Vizito të gjitha.",
    "ad space": "Hapësirë për reklamim",
    "building space ad": "Kjo hapësirë është për reklamimin e ndërtesve.",
    "contact us at":
      "Na kontaktoni në numrin e telefonit 38344545453 ose ne kontakto@kulmi-ks.com",
    "guide map": "Harta e faqes",
    contact: "Kontakti",
    manage: "Manage",
    "my posts": "Postimet e mia",
    account: "Llogaria",
    logout: "Ç'kyçu",
    login: "Kyçu",

    "new user": "Jeni i ri në faqen KulmiKs",
    register: "Regjistrohuni",
    "write email here": "Shkruani emailin ketu...",
    "write pass here": "shkruani fjalekalimin ketu...",
    "reset pass": "Reset fjalekalimin",
    "create account": "Krijo llogari dhe bëhu pjesë e Kulmit",
    "you have account": "Keni një llogari?",
    "login here": "Kyquni ketu",

    people: "Personat",
    "car model": "Modeli vertures",
    "searching posts": "Postimet per kerkim",
    "add post": "Shto postim",

    home: "Shtepi",
    apartment: "Banesë",
    land: "Tokë",
    office: "Zyrë",
    villa: "Villë",
    local: "Lokal",
    food: "Ushqim",
    coffee: "Kafe",
    weddings: "Dasma",
    Dasma: "Dasma",
    music: "Muzikë (Party)",
    "owning paper": "Fletë poseduese",
    "upload photo": "Ngarko fotografi",
    "select neighborhood":
      "Ju lutem të caktoni ne vijim lagjen e saktë në mënyrë që postimi juaj të gjendet më shpejtë. Më poshtë keni mundësinë e fshehjes së hartës nga postimi juaj.",
    "select location": "Cakto lokacionin në hartë:",
    "do not show map": "Mos shfaq harten ne postim",
    "add this post": "Shto postimin",
    preview: "Pamja paraprake",
    "chosen photo": "Ketu vendoset fotoja e perzgjedhur",
    "chosen title": "titulli vendoset ketu",
    "posted on": "Postuar më",
    month: "Muaj",
    day: "Ditë",

    "main photo": "Caktoni foton kryesore per postin tuaj*",

    details: "Detajet",

    general: "Te pergjithshme",
    description: "Pershkrimi",
    extra: "Shtesat",
    map: "Lokacioni",
    send: "Dergo",
    "contact owner": "Kontakto Pronarin",
    "Emri..": "Emri",
    "Mbiemri..": "Mbiemri",
    "Numri telefonit..": "Numri telefonit",
    "Mesazhi..": "Mesazhi",
    delete: "Fshij",
    back: "Kthehu",
    "click to zoom": "Kliko per te zmadhuar foton",
    update: "Perditeso",
    "make active": "Beje aktive",
    archive: "Arkivo postimin",
    "one room": "Garsoniere",
    seats: "Ulese",
    "manufactured at": "Prodhuar me",
    doors: "Dyer",
    transmission: "Transmisioni",
    petrol: "Karburanti",
    colors: "Ngjyrat",
    bathrooms: "Banjo",
    "owning letter": "Leter poseduese",
    "fb contact": "Kontakto ne facebook",
    "in place deal": "Me marreveshje",
    agency: "Agjensioni",
    email: "Email",
    "fb link": "Linku ne facebook",
    "posted at": "Postuar me",
    "Upload photos": "Ngarko fotot",
    "chose up to 15": "Zgjedh deri ne 15 foto",
    "chosen photo": "Foto e perzgjedhur vendoset ketu",
    "chosen title": "Titulli vendoset ketu",

    fun: "Ahengje",

    //forms
    Qera: "Qera",
    Blej: "Blej",
    Shitje: "Shitje",
    "Cimer/e": "Cimer/e",
    "Lloji i postimit": "Lloji i postimit",
    Banesë: "Banesë",
    Shtëpi: "Shtëpi",
    Zyrë: "Zyrë",
    Tokë: "Tokë",
    
    Villë: "Villë",
    Banese: "Banesë",
    Shtepi: "Shtëpi",
    Zyre: "Zyrë",
    Toke: "Tokë",
    
    Villë: "Villë",
    Lokal: "Lokal",
    Ahengje: "Ahengje",
    "Të gjitha": "Të gjitha",
    Çmimi: "Çmimi",
    Party: "Party",
    Ushqim: "Ushqim",
    Kafe: "Kafe",
    Personat: "Personat",
    "Modeli vetures": "Modeli vetures",
    "Muzikë (Party)": "Muzikë (Party)",
    "Titulli i postimit*": "Titulli i postimit*",
    "Currency":"Valuta",
    "Currency*":"Valuta",
    "Qyteti/Rajoni*": "Qyteti/Rajoni*",
    "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)":
      "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)",
    "Marka*": "Marka*",
    "Hapësira (m2)*": "Hapësira (m2)*",
    "Hapësira m2 (vendosni vetem numrin)*":
      "Hapësira m2 (vendosni vetem numrin)*",
    "Modeli*": "Modeli*",
    "Modeli vetures*": "Modeli vetures*",
    "Transmisioni*": "Transmisioni*",
    "Ngjyra vetures*": "Ngjyra vetures*",
    "Ngjyra*": "Ngjyra*",
    "Karburanti*": "Karburanti*",
    "Lloji karburantit*": "Lloji karburantit*",
    "Viti prodhimit*": "Viti prodhimit*",
    "Kilometrazha*": "Kilometrazha*",
    "Numri dyerve*": "Numri dyerve*",
    "Numri uleseve*": "Numri uleseve*",
    "Vendi origjines*": "Vendi origjines*",
    "Banjo*": "Banjo*",
    "Numri kateve te shtepise*": "Numri kateve te shtepise*",
    'Dhoma*(Vendos 0 nëse dëshironi të figuroj "Garsonjerkë")*':
      'Dhoma*(Vendos 0 nëse dëshironi të figuroj "Garsonjerkë")*',
    "Dhoma*": "Dhoma*",
    "Linku i profilit ne facebook:(opsional)":
      "Linku i profilit ne facebook:(opsional)",
      
    'Unit*':'Njesia matese*',
    'Unit':'Njesia matese',
    "Numri i katit (vendos 0 për përdhesë)*":
      "Numri i katit (vendos 0 për përdhesë)*",
    "Numri personave te lejuar*": "Numri personave te lejuar*",
    "Fletë poseduese*": "Fletë poseduese*",
    "I doganuar*": "I doganuar*",
    Përshkrimi: "Përshkrimi",
    Karakteristikat: "Karakteristikat",
    "Çmimi* (Vendos 0 nëse dëshironi të figuroj 'Me marrëveshje')":
      "Çmimi* (Vendos 0 nëse dëshironi të figuroj 'Me marrëveshje')",
    "Çmimi*": "Çmimi",
    "Çmimi ditor*": "Çmimi ditor",
    "Zgjedh deri ne 15 foto*": "Zgjedh deri ne 15 foto",
    "Posti u shtua me sukses!": "Posti u shtua me sukses!",
    "Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri.":
      "Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri.",
    "Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perseri.":
      "Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perseri.",
    "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto":
      "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto",
    "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto":
      "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto",
    "Se shpejti!": "Se shpejti!",
    "Kërko lokacionin..": "Kërko lokacionin..",
    value: "value",
    "Kërko qytetin/lagjen": "Kërko qytetin/lagjen",
    "Te gjitha": "Te gjitha",
    "Kërko":"Kërko",
    'Kërko veturën':'Kërko veturën',
    
    'dhoma':'dhoma',
    'dhomë':'dhomë',
    'banjo':'banjo',
    'm2':'m2',
    'Viti':'Viti',
    'Automatik':'Automatik',
    'kate':'kate',

    "Wifi":"Wifi",
      "Parking":"Parking",
      "TV i mençur":"TV i mençur",
      "Depo":"Depo",
      "Nxemje qendrore":"Nxemje qendrore",
      "Klimë":"Klimë",
    
    //about us
    'line1':'Kush jemi ne?',
    'line2':'Ne jemi Website që ofron mundësi për shpallje/gjetjen e të gjitha patundshmërive të ndryshme veturave me qera apo shitje,',
    'line3':'po ashtu për të gjetur të gjitha llojet e gastronomis!',
    'line4':'Ne nuk jemi agjent dhe nuk shesim/lëshojmë me qera banesa/shtëpi, ne jemi ndërmjetësues.',
    'line5':'Ndërmjetësues mes shitësit dhe blerësit, qiradhënësit dhe qiramarrësit, po ashtu dhe shitësit me agjent, qiradhënësit me agjent!',
    'line6':'Ne ofrojmë publikimin e shitjeve dhe lëshimin me qera të veturave.',
    'line7':'Po ashtu ofrojmë shpalljen/gjetjen e restoranteve dhe bareve të ndryshme.',
    'line8':'Çka ofrojmë ne ? ',
    'line9':'Banesa\shtëpi me qera',
    'line10':'Banesa\shtëpi në shitje',
    'line11':'Vetura me qera',
    'line12':'Vetura ne shitje',
    'line13':'Gastronomi',
    'line14':'Restaurante dhe bare te ndryshme',
    'line15':'Të shpallura nga:',
    'line16':'Pronar apo agjent i patundshmeris',
    'line17':'Restorant',
    'line18':'Rent a car apo Auto sallon',
    'line19':'Cili është qëllimi jonë?',
    'line20':'Të ju ofrojmë zgjidhje më të shpejtë dhe më të lehtë për blerjen apo shitjen që do e bëni ashtu siç e dëshironi dhe aty ku e dëshironi.',
    'line21':'Faqja u dizajnua nga Adonis Rexha, Logo nga Fatos Rexhepi dhe u zhvillua nga Arbër Kadriu',
  },
};
