export default {
  translation: {
    albanian: "Albanian",
    english: "English",
    "select language": "Change language",
    dashboard: "Dashboard",
    "find it": "How you ask for it, you find it!",
    "all types":
      "All types of REAL ESTATE for rent or sale, the best RESTAURANTS as well as CARS for rent and sale in kulmi-ks.",
    "about us": "About us",
    "real estate": "Real estate",
    gastronomi: "Gastronomy",
    cars: "Cars",
    rent: "Rent",
    buy: "Buy",
    contactus: "Contact us",
    "room mate": "Room mate",
    "find city": "Find yout city/neighborhood",
    "post type": "Post type",
    price: "Price",
    min: "min",
    max: "max",
    search: "Search",
    "quick solutions": "We offer quick selection for you!",
    "real estate as you like": "House or flat as you are looking for",
    "your preferences": "You can find your preferences with us",
    "best gastronomy": "The best restaurants in Kosovo",
    "gastronomy types": "Different categories of gastronomy",
    "fast find": "Quick search for comfortable driving",
    "rent buy cars": "Cars for rent or sale",
    "find quick": "You find it very quickly",
    "find within 5 minutes":
      "Within 5 minutes you will find what you are looking for",
    "best apartments": "The best apartments",
    "best offers": "We bring you the best offers.",
    "visit all": "Visit all.",
    "ad space": "Space for advertising",
    "building space ad": "This space is for advertising buildings.",
    "contact us at":
      "Contact us at phone number 38344545453 or at kontakto@kulmi-ks.com",
    "guide map": "Site map",
    contact: "Contact",
    manage: "Manage",
    "my posts": "My posts",
    account: "Account",
    logout: "Log out",
    login: "Log in",

    "new user": "You are new to the KulmiKs site",
    register: "Sign up",
    "write email here": "Enter your email here...",
    "write pass here": "Enter the password here...",
    "reset pass": "Reset password",
    "create account": "Create an account and become part of Kulm",
    "you have account": "Do you have an account?",
    "login here": "Click here",

    people: "Person",
    "car model": "Car model",
    "searching posts": "Search posts",
    "add post": "Add post",

    home: "Home",
    apartment: "Apartment",
    land: "Land",
    office: "Office",
    villa: "Villa",
    local: "Local",
    food: "Food",
    coffee: "Coffee",
    weddings: "Weddings",
    music: "Music  (Party)",
    "owning paper": "Owning paper",
    "upload photo": "Upload photos",
    "select neighborhood":
      "Please specify the correct neighborhood below so that your post can be found faster. Below you have the option to hide the map from your post.",
    "select location": "Set the location on the map:",
    "do not show map": "Do not show the map in the post",
    "add this post": "Add post",
    preview: "Preview",
    "chosen photo": "The selected photo is placed here",
    "chosen title": "The title is placed here",
    "posted on": "Posted on",
    month: "Month",
    day: "Day",
    "main photo": "Set main photo for your post*",

    details: "Details",

    //postdetails page
    general: "General Information",
    description: "Description",
    extra: "Extra",
    map: "Location",
    send: "Send",
    "contact owner": "Contact owner",
    "Emri..": "Name",
    "Mbiemri..": "Last Name",
    "Numri telefonit..": "Phone number",
    "Mesazhi..": "Message",
    delete: "Delete",
    back: "Back",
    "click to zoom": "Click to zoom",
    update: "Update",
    "make active": "Make active",
    archive: "Archive post",
    "one room": "One room",
    seats: "Seats",
    "manufactured at": "Manufactured at",
    doors: "Doors",
    transmission: "Transmission",
    petrol: "Petrol type",
    colors: "Colors",
    bathrooms: "Bathrooms",
    "owning letter": "Owning letter",
    "fb contact": "Contact on facebook",
    "in place deal": "In place deal",
    agency: "Agency",
    email: "Email",
    "fb link": "Facebook link",
    "posted at": "Posted at",

    "Upload photos": "Upload photos",
    "chose up to 15": "Choose up to 15 photos",
    "chosen photo": "Chosen photo is put here",
    "chosen title": "Chosen title is put here",

    fun: "Fun",
    Qera: "Rent",
    Blej: "BUY",
    Shitje: "SALE",
    "Cimer/e": "Roommate",
    "Lloji i postimit": "Post type",
    Banesë: "Apartment",
    Shtëpi: "house",
    Zyrë: "Bureau",
    Tokë: "Earth",
    Villë: "Villa",
    Banese: "Apartment",
    Shtepi: "House",
    Zyre: "Bureau",
    Toke: "Earth",
    Ville: "Villa",
    Lokal: "Cafeteria",
    Ahengje: "Celebrations",
    "Të gjitha": "Everything",
    Çmimi: "Price",
    Dasma: "Weedings",
    Party: "Party",
    Ushqim: "Food",
    Kafe: "Coffee",
    Personat: "People",
    "Modeli vetures": "Car model",
    "Muzikë (Party)": "Music (Party)",
    "Titulli i postimit*": "Post Title*",
    "Currency":"Currency",
    "Currency*":"Currency",
    "Prona": "Property",
    "Qyteti/Rajoni*": "City/Region*",
    "Marka* (nëse ke më shumë se një makinë kliko të gjitha markat që i posedoni)":
      "Brand* (if you have more than one car, click all the brands you own)",
    "Marka*": "Brand*",
    "Hapësira (m2)*": "Space (sqft)*",
    "Hapësira m2 (vendosni vetem numrin)*": "Space sqft (only enter the number)*",
    "Modeli*": "Model*",
    "Modeli vetures*": "Car model*",
    "Transmisioni*": "Transmission*",
    "Ngjyra vetures*": "Car color*",
    "Ngjyra*": "Color*",
    "Karburanti*": "Fuel*",
    "Lloji karburantit*": "Fuel Type*",
    "Viti prodhimit*": "Year of manufacture*",
    "Kilometrazha*": "Mileage*",
    "Numri dyerve*": "Number of doors*",
    "Numri uleseve*": "Number of seats*",
    "Vendi origjines*": "Place of origin*",
    "Banjo*": "Bathroom*",
    "Numri kateve te shtepise*": "Number of floors of the house*",
    'Dhoma*(Vendos 0 nëse dëshironi të figuroj "Garsonjerkë")*':
      'Room*(Put 0 if you want to figure "Studio")*',
    "Dhoma*": "Room*",
    "Linku i profilit ne facebook": "(optional)",
    "Numri i katit (vendos 0 për përdhesë)*":
      "Floor number (set 0 for ground floor)*",
    "Numri personave te lejuar*": "Number of persons allowed*",
    "Fletë poseduese*": "Flete poseduese*",
    "I doganuar*": "Customs cleared*",
    Përshkrimi: "Description",
    Karakteristikat: "Characteristics",
    "Çmimi* (Vendos 0 nëse dëshironi të figuroj Me marrëveshje)":
      'Price* (Enter 0 if you want to show "Negotiate")',
    "Çmimi*": "Price",
    "Çmimi ditor*": "Daily price",
    "Zgjedh deri ne 15 foto*": "Choose up to 15 photos",
    "Posti u shtua me sukses!": "Post successfully added!",
    "Sesioni juaj ka kaluar, ju lutem të kyçeni përsëri.":
      "Your session has expired, please log in again.",
    "Ndodhi nje problem me dergimin e te dhenave, ju lutem te provoni perseri.":
      "There was a problem sending the data, please try again.",
    "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto":
      "One of the selected images was a duplicate image, it was only taken once as a photo",
    "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto":
      "You have selected more than the allowed number, the maximum is 15 photos",
    "Një nga imazhet e selektuara ka qenë imazh i dublifikuar, është marrë vetëm një herë si foto":
      "One of the selected images was a duplicate image, it was only taken once as a photo",
    "Keni zgjedhur më shumë se numri i lejuar, maksimumi është 15 foto":
      "You have selected more than the allowed number, the maximum is 15 photos",
    "Se shpejti!": "Soon!",
    "Kërko lokacionin..":"Search for location..",
    "Kërko qytetin/lagjen":"Search city/region",
    "Te gjitha":"All",
    "Kërko":"Search",
    'Kërko veturën':'Search car',
    "Ju lutem te mbushni te dhenat":"",
    "Të dhënat janë gabim, provoni përsëri":"",

    'dhoma':'rooms',
    'dhomë':'room',
    'banjo':'bathrooms',
    'm2':'sqft',
    'Viti':'year',
    'Automatik':'automatic',
    'kate':'stories',

    'Unit*':'Measurement unit*',

    "Wifi":"Wifi",
      "Parking":"Parking",
      "TV i mençur":"Smart TV",
      "Depo":"Storehouse",
      "Nxemje qendrore":"Central heating",
      "Klimë":"AC",


    //about us
    'line1':'Who we are?',
    'line2':'We are a website that offers opportunities for advertising/finding all different properties, cars for rent or sale,',
    'line3':'also to find all kinds of gastronomy!',
    'line4':'We are not an agent and we do not sell/rent flats/houses, we are mediators.',
    'line5':'Intermediary between sellers and buyers, landlords and tenants, also sellers with agent, landlords with agent!',
    'line6':'We offer the publication of sales and the release of car rentals.',
    'line7':'We also offer advertising/finding of various restaurants and bars.',
    'line8':'What do we offer? ',
    'line9':'Apartment/house for rent',
    'line10':'Flat/house for sale',
    'line11':'Car rental',
    'line12':'Cars for sale',
    'line13':'Gastronomie',
    'line14':'Various restaurants and bars',
    'line15':'Announced by:',
    'line16':'Owner or real estate agent',
    'line17':'Restaurant',
    'line18':'Rent a car or Auto salon',
    'line19':'What is our purpose?',
    'line20':'To offer you faster and easier solutions for buying or selling that you will do as you want and where you want.',
    'line21':'The site was designed by Adonis Rexha, Logo by Fatos Rexhepi and developed by Arbër Kadriu',
  },
};
