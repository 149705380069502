import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
// import './assets/sass/light-bootstrap-dashboard-react.scss'

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/demo.css";
import "./assets/css/customStyle.css";
import "semantic-ui-css/semantic.min.css";
import "react-medium-image-zoom/dist/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "react-photoswipe/lib/photoswipe.css";
import loadable from "@loadable/component";
import './i18configuration/i18';
const UserLayout = loadable(() => import("../src/layouts/User.jsx"));

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/user" render={(props) => <UserLayout {...props} />} />
      <Redirect from="/*" to="/user/dashboard" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
